import { EditorScriptFlowAPI, FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { EditorReadyOptions } from '@wix/platform-editor-sdk';
import { openShowHidePanel } from '../../panels/showHidePanel/showHideActions';
import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';

export const onClickWidgetGFPP = async (
  editorSDK: FlowEditorSDK,
  flowAPI: EditorScriptFlowAPI,
  options: EditorReadyOptions,
  event,
) => {
  const { id, componentRef } = event.detail;
  const panelsApi = await new PanelsApiFactory().createPanelsApi({
    editorSDK,
    editorType: options.origin.type,
  });

  switch (id) {
    case 'openShowHidePanel': {
      const preset = editorSDK.document.application.appStudioWidgets.getPreset(
        'token',
        {
          componentRef,
        },
      );
      preset.then((res: any) => {
        // console.log({ res });
        void openShowHidePanel(
          editorSDK,
          componentRef,
          res.layout,
          flowAPI.translations.t,
        );
      });
      break;
    }
    case 'openSmsPanel':
      await panelsApi.openBlocksPanel('smsSettings', componentRef);
      break;
  }
};
