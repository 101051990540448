import { ComponentRef, FlowEditorSDK } from '@wix/yoshi-flow-editor';

export const removeElement = async (
  editorSDK: FlowEditorSDK,
  event,
  role: string,
) => {
  const { componentRef } = event.detail;

  const collapseReferredComponent = async (componentRef: ComponentRef) => {
    editorSDK.components.refComponents.collapseReferredComponent('', {
      componentRef,
    });
  };

  const findAllByRole = async (controllerRef: ComponentRef, role: string) => {
    return editorSDK.document.components.findAllByRole('', {
      controllerRef,
      role,
    });
  };

  const removeElementFromPanel = async ({ componentRef }): Promise<void> => {
    try {
      const [controllerRef] = await editorSDK.components.getChildren('', {
        componentRef,
      });
      const [elementRef] = await findAllByRole(controllerRef, role);
      const parent = await editorSDK.document.components.refComponents.getHostComponentRef('', {componentRef:elementRef});
      await collapseReferredComponent(parent);
    } catch (e) {
      console.error('removeElementFromPanel', { e });
    }
  };

  const getCompToHide = async (componentRef) => {
    const type = await editorSDK.components.getType('token', { componentRef });
    return type.includes('AppWidget')
      ? (await editorSDK.components.getAncestors('t', { componentRef }))[0]
      : /* istanbul ignore next reason: we don't hide whole widget */ componentRef;
  };

  const compToHide = await getCompToHide(componentRef);
  await removeElementFromPanel({ componentRef: compToHide });
};
