import { HttpClient } from '@wix/http-client';
import { devUrl, prodUrl } from '../constants';
import { IHttpClient } from '@wix/yoshi-flow-editor';

const httpClient = new HttpClient();

const serverUrl = prodUrl;

export default function get<T>(
  url: string,
  instance: string,
  client?: IHttpClient | undefined,
): Promise<T> {
  const clientHttp = client ?? httpClient;
  return clientHttp
    .get<T>(`${serverUrl}/api${url}`, {
      headers: {
        Authorization: instance,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.data);
}

export function post<T>(url: string, instance: string, data: any): Promise<T> {
  return httpClient
    .post<T>(`${serverUrl}/api${url}`, data, {
      headers: {
        Authorization: instance,
        'Content-Type': 'application/json',
      },
    })
    .then((res) => res.data);
}

export function externalGet(url: string): Promise<any> {
  return httpClient.get(url).then((res) => res.data);
}

export function externalPost<T>(
  url: string,
  headers: any,
  data: any,
): Promise<T> {
  return httpClient
    .post<T>(url, data, {
      headers,
    })
    .then((res) => res.data);
}

export function getImage(url: string) {
  return `${serverUrl}/assets/${url}`;
}
