import { bi } from '../../utils/bi';
import preferredAppToKey from '../../utils/preferredAppToKey';
import model from './model';
import { getAppImages, getSiteName } from '../../api';
import { generateQRCode } from '../../api/utils/qr-code';

let data: any = null;
export { data };

export default model.createController(({ $w, $widget, flowAPI }) => {
  let isAppLoaded = false;

  $widget.onPropsChanged((oldProps, newProps) => {
    debugger;
    if (newProps.title !== oldProps.title) {
      $w('#title').text = newProps.title;
    }
    if (newProps.description !== oldProps.description) {
      $w('#description').text = newProps.description;
    }
  });

  function setText() {
    const { preferredApp, siteName } = data;
    debugger;
    const titleProp = $widget.props.title;
    $w('#title').text =
      titleProp !== 'default_prop'
        ? titleProp
        : flowAPI.translations.t(
            `download_my_app.widget.title${
              preferredApp === 'dine' ? '.dine' : ''
            }`,
          );
    // if ($w('#title').text === 'default_title') {
    //   // $w('#title').text = flowAPI.translations.t(
    //   //   `download_my_app.widget.title${preferredApp == 'dine' ? '.dine' : ''}`
    //   // );
    // }
    const descriptionProp = $widget.props.description;
    $w('#description').text =
      descriptionProp !== 'default_prop'
        ? descriptionProp
        : flowAPI.translations.t(
            `download_my_app.widget.description.${preferredApp}`,
            { siteName },
          );
    // $w('#description').text = flowAPI.translations.t(
    //   `download_my_app.widget.description.${preferredApp}`,
    //   { siteName }
    // );
    $w('#text1').text = flowAPI.translations.t(
      `download_my_app.widget.qr_code_description`,
    );
  }

  return {
    pageReady: async () => {
      $widget.fireEvent('widgetLoaded', {});
      if (!isAppLoaded) {
        const {
          title: siteName,
          url,
          metaSiteId,
          preferredApp,
        } = await getSiteName(flowAPI);
        $w('#phoneInput2').appUrl = url;
        $w('#badges2').metaSiteId = metaSiteId;

        const app = preferredAppToKey(preferredApp);
        $w('#badges2').preferredApp = app;
        $w('#phoneInput2').preferredApp = app;

        const designSettings = await getAppImages(flowAPI);
        $w('#device1').logoImageUrl = designSettings.logoImageUrl || '';
        $w('#device1').showLogoInHeader = designSettings.showLogoInHeader;
        $w('#device1').primaryColor = designSettings.primaryColor;
        const [coverImageUrl = ''] = designSettings.coverImagesUrls || [];
        $w('#device1').coverImageUrl = coverImageUrl;
        $w('#device1').siteName = designSettings.headerTitle || siteName;
        $w('#phoneInput2').siteName = designSettings.headerTitle || siteName;

        const qrCode = generateQRCode(url);
        $w('#qrCode3').qrCode = qrCode;

        data = {
          preferredApp: app,
          siteName: designSettings.headerTitle || siteName,
          qrCode,
          designSettings,
          metaSiteId,
        };
        bi(flowAPI).sympAppLoaded();
        setText();
        isAppLoaded = true;
      } else {
        bi(flowAPI).sympAppLoaded();
      }
    },
    exports: {},
  };
});
