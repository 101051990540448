import { FlowEditorSDK } from '@wix/yoshi-flow-editor';
import { EditorReadyOptions } from '@wix/platform-editor-sdk';
import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import { getSiteName } from '../../api';

export const onClickComponentGFPP = async (
  editorSDK: FlowEditorSDK,
  flowAPI: any,
  options: EditorReadyOptions,
  event,
) => {
  const { id, componentRef } = event.detail;
  const panelApiFactory = new PanelsApiFactory();
  const panelsApi = await panelApiFactory.createPanelsApi({
    editorSDK,
    editorType: options.origin.type,
  });

  switch (id) {
    case 'openButtonwithoutLinkPanel': {
      editorSDK.editor.openNativeComponentPanel<'StylableButton'>(
        'token',
        'settings',
        {
          componentRef,
          configuration: {
            controlGroups: {},
            controls: {
              link: { hidden: true },
              label: { hidden: true },
              icon: { hidden: false },
            },
            states: {},
          },
        },
      );
      break;
    }
    case 'openTitlePanelSettings': {
      editorSDK.editor.openNativeComponentPanel<'WRichText'>(
        'token',
        'settings',
        {
          componentRef,
          configuration: {
            controls: {
              link: { hidden: true },
              alignment: { hidden: false },
              bulletsAndNumbering: { hidden: false },
            },
            controlGroups: {
              seoA11y: { hidden: false },
              verticalText: { hidden: true },
            },
            states: {},
          },
        },
      );
      break;
    }
    case 'openDescriptionSettingsPanel': {
      editorSDK.editor.openNativeComponentPanel<'WRichText'>(
        'token',
        'settings',
        {
          componentRef,
          configuration: {
            controls: {
              link: { hidden: true },
              alignment: { hidden: false },
              bulletsAndNumbering: { hidden: false },
            },
            controlGroups: {
              seoA11y: { hidden: false },
              verticalText: { hidden: true },
            },
            states: {},
          },
        },
      );
      break;
    }
    case 'openSiteNameSettings': {
      await panelsApi.openBlocksPanel('siteName2', componentRef);
      break;
    }
    case 'editTitle': {
      await panelsApi.openBlocksPanel('editTitlePanel', componentRef, {
        overrides: {
          title: flowAPI.translations.t('download_my_app.panel.title.title'),
        },
      });
      break;
    }
    case 'editDescription': {
      const { preferredApp, title } = await getSiteName(flowAPI);
      const widgetHost =
        await editorSDK.components.refComponents.getHostComponentRef('token', {
          componentRef,
        });
      const children = await editorSDK.components.getChildren('token', {
        componentRef: widgetHost,
      });
      const ref = children[0];
      await editorSDK.application.appStudioWidgets.props.set('token', {
        widgetRef: ref,
        newProps: { preferredApp, siteName: title },
      });
      await panelsApi.openBlocksPanel('editDescPanel', componentRef, {
        overrides: {
          title: flowAPI.translations.t(
            'download_my_app.panel.description.title',
          ),
        },
      });
      break;
    }
  }
};
