import { PlatformControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { DEFAULT_APP, SUPPORTED_APPS } from '../constants';

export interface APIPreferredAppResponse {
  wixPreferredApp: string;
  ownerPreferredApp: string;
}
export const getPreferredApp = async (flowApi: PlatformControllerFlowAPI) => {
  try {
    const { data } = await flowApi.httpClient.get<APIPreferredAppResponse>(
      `/wixapp/api/v1/preferred-apps/preference`,
      {
        headers: {
          Authorization: flowApi.controllerConfig.appParams.instance,
        },
      },
    );
    const { wixPreferredApp = '', ownerPreferredApp = '' } = data;
    const app = ownerPreferredApp || wixPreferredApp;
    const preferredApp = SUPPORTED_APPS.includes(app) ? app : DEFAULT_APP;

    return preferredApp;
  } catch (err) {
    return DEFAULT_APP;
  }
};
