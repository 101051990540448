export const devUrl = 'http://localhost:3800';
export const prodUrl = 'https://wixlabs-dma.uc.r.appspot.com';
export const defaultHelpId = 'd1fe683b-8037-465f-a62c-9fc3a0e9d914';
export const DEFAULT_APP = 'WixOneApp';
export const SUPPORTED_APPS = [
  DEFAULT_APP,
  'WixFitnessApp',
  'WixRestaurantsApp',
  'WixBrandedApp',
];
